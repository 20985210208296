$(document).foundation();

// Entry Form validation

$('#agency-yes').click(function(){
	$('.network-name').fadeIn('slow');
});

$('#agency-no').click(function(){
	$('.network-name').hide();
});


/*function captcha () {
	$('.captcha-res').val($('#g-recaptcha-response').val());
	if($('.captcha-res').hasClass('is-invalid-input')){
		$('.captcha.is-invalid-label').show();
	}else{
		$('.captcha.is-invalid-label').hide();
	}
};*/

